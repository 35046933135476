import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { OvalButton } from '~/components/button';
import { ButtonBackIcon } from '~/components/icon';
import { Spacing } from '~/components/spacing';

export type HeaderBackButtonProps = {
    onPress?: () => void;
};

export function HeaderBackButton(props: HeaderBackButtonProps) {
    const { onPress } = props;
    const { goBack } = useNavigation();
    return (
        <View style={{ paddingLeft: Spacing.MEDIUM }}>
            <OvalButton size="small" icon={ButtonBackIcon} onPress={onPress ?? goBack} />
        </View>
    );
}

HeaderBackButton.displayName = 'HeaderBackButton';
