import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';
import React, { useCallback, useRef } from 'react';
import { Animated } from 'react-native';

import HeroImage from '~/assets/images/shining-heart.svg';
import { useIntl } from '~/contexts/intl';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { AnimatedLogoHeader } from '../home';

import { AuthInstruction } from './auth-instruction';

type AuthAuthenticateNavigationProp = StackNavigationProp<AppNavigatorParamList, 'authenticate'>;

export const AuthAuthenticate = () => {
    const { navigate, goBack } = useNavigation<AuthAuthenticateNavigationProp>();
    const { formatMessage } = useIntl();

    const offset = useRef(new Animated.Value(0)).current;

    const next = useCallback(() => navigate('verify'), [navigate]);
    const back = useCallback(() => goBack(), [goBack]);

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    return (
        <>
            {web && <AnimatedLogoHeader offset={offset} />}
            <AuthInstruction
                devMenuAccess
                renderEllipticMask
                image={web ? <HeroImage width="272" height="272" viewBox="0 0 350 350" /> : <HeroImage />}
                title={formatMessage('auth-authenticate.title')}
                description={formatMessage('auth-authenticate.description')}
                buttonLabel={formatMessage('auth-authenticate.button.title')}
                onButtonPress={next}
                backButtonLabel={formatMessage('previous')}
                backOnButtonPress={back}
            />
        </>
    );
};
