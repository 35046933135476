import { NavigationProp, useNavigation } from '@react-navigation/native';
import React from 'react';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { EIdentWeb, EIdentWebVerify, EIdentWebError } from '~/components/e-ident/e-ident.web';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

type AuthVerifyNavigationProp = NavigationProp<AppNavigatorParamList, 'verify'>;

const ScreenContainer = styled.View`
    flex: 1;
    background-color: ${Color.BACKGROUND_TERTIARY};
`;

export function AuthVerify() {
    return (
        <ScreenContainer>
            <EIdentWeb />
        </ScreenContainer>
    );
}

export function AuthVerifyEident() {
    const { goBack } = useNavigation<AuthVerifyNavigationProp>();
    return (
        <ScreenContainer>
            <EIdentWebVerify onVerificationCompleted={result => (result.success ? result.commit() : goBack())} />
        </ScreenContainer>
    );
}

export function AuthVerifyEidentError() {
    return (
        <ScreenContainer>
            <EIdentWebError />
        </ScreenContainer>
    );
}
