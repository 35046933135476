import { RouteProp } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { LinkButton } from '~/components/button';
import { Color } from '~/components/color';
import { EllipticMaskView } from '~/components/elliptic-mask';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { PinCodeDisplay, PinCodeKeyboard } from '~/components/pin-code';
import { SCREEN_WIDTH_RATIO } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { useSMSCodeAuthentication } from '~/hooks/sms-authentication/sms-authentication';
import { AppNavigatorParamList, UnauthenticatedNavigatorParamList } from '~/navigator/app-navigator';

export type AuthSMSAuthenticateNavigation = StackScreenProps<AppNavigatorParamList, 'sms-authentication'>['navigation'];

const ScreenContainer = styled.View<{ bottomInset: number }>`
    flex: 1;
    background-color: ${Color.BACKGROUND_DEFAULT};
    padding-bottom: ${({ bottomInset }) => bottomInset}px;
`;

const HeaderContainer = styled.View<{ topInset: number }>`
    align-items: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
    padding-top: ${({ topInset }) => topInset + Spacing.SMALL}px;
    padding-bottom: ${Spacing.SMALL}px;
`;

const TopContainer = styled.View`
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${Color.BACKGROUND_SECONDARY};
`;

const BottomContainer = styled.View`
    align-items: center;
    padding: 0 ${Spacing.MEDIUM}px ${Spacing.MEDIUM}px;
`;

const ForgottenLinkContainer = styled.View`
    padding: ${Spacing.MEDIUM}px 0 ${Spacing.HUGE}px;
`;

type AuthSMSRouteProp = RouteProp<UnauthenticatedNavigatorParamList, 'sms-authentication'>;

export const AuthSmsAuthentication: React.FC = () => {
    const {
        params: { token }
    } = useRoute<AuthSMSRouteProp>();

    const { top, bottom } = useSafeAreaInsets();
    const { formatMessage } = useIntl();

    const { states, inputValue, message, onInput, onDelete, loading, resendSMS } = useSMSCodeAuthentication({
        tokenFromEmail: token
    });

    return (
        <>
            <StatusBar style="dark" />

            <ScreenContainer bottomInset={bottom}>
                <HeaderContainer topInset={top}>
                    <HorizontalLogo />
                </HeaderContainer>

                <EllipticMaskView ellipseSize="large" style={{ flex: 1 }}>
                    <TopContainer>
                        <PinCodeDisplay size={SCREEN_WIDTH_RATIO < 1.0 ? 'small' : 'standard'} states={states} />
                        <Spacing.Vertical.MEDIUM />
                        <Text.P1 style={{ paddingHorizontal: Spacing.MEDIUM }}>{message}</Text.P1>
                    </TopContainer>
                </EllipticMaskView>

                <Spacing.Vertical.MEDIUM />

                <BottomContainer>
                    <ForgottenLinkContainer>
                        <LinkButton
                            size="small"
                            label={formatMessage('auth-sms-authenticate.resend-code')}
                            onPress={resendSMS}
                        />
                    </ForgottenLinkContainer>

                    <PinCodeKeyboard value={inputValue} onKeyPress={onInput} onDelete={onDelete} disabled={loading} />
                </BottomContainer>
            </ScreenContainer>
        </>
    );
};
