import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import styled from 'styled-components/native';

import { Color } from '~/components/color';
import { Markdown } from '~/components/markdown';
import { Text } from '~/components/text';
import { useAppointmentsSelection } from '~/contexts/appointments-selection';
import { AppError } from '~/error';
import { useAppointmentsContextDescription } from '~/hooks/appointments-context-description/appointments-context-description';

import { AppointmentsNavigationProp } from './appointments';

const AppointmentContextDescription = styled.View`
    margin-bottom: 24px;
`;

export const AppointmentsContentContextDescription = () => {
    const { calendarReservationAccessId, region, location, filters } = useAppointmentsSelection();
    const { goBack } = useNavigation<AppointmentsNavigationProp>(); // TODO: AppointmentsNavigationProp is defined in two places now
    const handleError = useErrorHandler();

    const { contextDescription, error } = useAppointmentsContextDescription(
        calendarReservationAccessId,
        filters,
        region,
        location
    );

    if (error) {
        handleError(
            new AppError(error, 'appointments.error.not-available', { name: 'error-overlay.go_back', onClose: goBack })
        );
    }

    return (
        <AppointmentContextDescription>
            {contextDescription ? (
                <Markdown
                    styles={{
                        heading1: { ...Text.SUBTITLE_1_STYLES, color: Color.SMOOTHIE },
                        heading2: { ...Text.PARAGRAPH_1_STYLES, marginTop: 8 },
                        heading3: Text.SUBTITLE_1_STYLES,
                        strong: Text.PARAGRAPH_2_STYLES
                    }}
                >
                    {contextDescription}
                </Markdown>
            ) : null}
        </AppointmentContextDescription>
    );
};
