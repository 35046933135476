import React, { useMemo } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Calendar, DateData, LocaleConfig } from 'react-native-calendars';
import { DayState } from 'react-native-calendars/src/types';
import styled from 'styled-components/native';

import { useIntl } from '~/contexts/intl';

import { Color } from '../color';
import { FontFamily, Text } from '../text';

import { CalendarProps } from './calendar-types';

const DayContainer = styled.View<{ selected: boolean }>`
    display: flex;
    padding: 8px;
    width: 36px;
    align-items: center;
    justify-content: center;

    ${({ selected }) => selected && `background-color: ${Color.FOCUS_DEFAULT}; borderRadius: 50px;`}
`;

export const CustomCalendar: React.FC<CalendarProps> = props => {
    const { loading, selectedDate, selectableDates, onSelectDate, month, minDate, maxDate } = props;
    const { formatMessage, locale } = useIntl();

    // These are values for react-native-calendars months and days, see https://github.com/wix/react-native-calendars
    const calendarLocaleConfig = useMemo(() => {
        const monthNames = [
            formatMessage('calendar.month.january'),
            formatMessage('calendar.month.february'),
            formatMessage('calendar.month.march'),
            formatMessage('calendar.month.april'),
            formatMessage('calendar.month.may'),
            formatMessage('calendar.month.june'),
            formatMessage('calendar.month.july'),
            formatMessage('calendar.month.august'),
            formatMessage('calendar.month.september'),
            formatMessage('calendar.month.october'),
            formatMessage('calendar.month.november'),
            formatMessage('calendar.month.december')
        ];

        const monthNamesShort = [
            formatMessage('calendar.month.january.short'),
            formatMessage('calendar.month.february.short'),
            formatMessage('calendar.month.march.short'),
            formatMessage('calendar.month.april.short'),
            formatMessage('calendar.month.may.short'),
            formatMessage('calendar.month.june.short'),
            formatMessage('calendar.month.july.short'),
            formatMessage('calendar.month.august.short'),
            formatMessage('calendar.month.september.short'),
            formatMessage('calendar.month.october.short'),
            formatMessage('calendar.month.november.short'),
            formatMessage('calendar.month.december.short')
        ];

        const dayNames = [
            formatMessage('calendar.day.sunday'),
            formatMessage('calendar.day.monday'),
            formatMessage('calendar.day.tuesday'),
            formatMessage('calendar.day.wednesday'),
            formatMessage('calendar.day.thursday'),
            formatMessage('calendar.day.friday'),
            formatMessage('calendar.day.saturday')
        ];

        const dayNamesShort = [
            formatMessage('calendar.day.sunday.short'),
            formatMessage('calendar.day.monday.short'),
            formatMessage('calendar.day.tuesday.short'),
            formatMessage('calendar.day.wednesday.short'),
            formatMessage('calendar.day.thursday.short'),
            formatMessage('calendar.day.friday.short'),
            formatMessage('calendar.day.saturday.short')
        ];

        const today = formatMessage('calendar.day.today');

        return { monthNames, monthNamesShort, dayNames, dayNamesShort, today };
    }, [formatMessage]);

    if (LocaleConfig?.locales) {
        LocaleConfig.locales[locale.toLowerCase()] = calendarLocaleConfig;
        LocaleConfig.defaultLocale = locale.toLowerCase();
    }

    const renderDayComponent = (dayProps: { date?: DateData; state?: DayState }) => {
        const { date, state } = dayProps;
        if (!date) {
            return <DayContainer selected={false} />;
        }

        const isDisabled = state === 'disabled' || (selectableDates && !selectableDates?.includes(date.dateString));
        const isSelected = state === 'selected' || date.dateString === selectedDate;
        const isToday = state === 'today';

        return (
            <DayContainer selected={isSelected}>
                <TouchableOpacity disabled={isDisabled} onPress={() => onSelectDate(date.dateString)}>
                    <Text.PARAGRAPH_1
                        style={
                            isToday && !isSelected
                                ? { color: Color.FOCUS_DEFAULT }
                                : isDisabled && !isSelected && !isToday
                                ? { color: Color.TEXT_DISABLED }
                                : isSelected
                                ? { color: Color.TEXT_TERTIARY }
                                : { color: Color.ALMOST_BLACK }
                        }
                    >
                        {date.day || ''}
                    </Text.PARAGRAPH_1>
                </TouchableOpacity>
            </DayContainer>
        );
    };

    return (
        <Calendar
            scrollEnabled
            disableAllTouchEventsForDisabledDays
            hideArrows
            hideExtraDays
            initialDate={month}
            displayLoadingIndicator={loading}
            minDate={minDate}
            maxDate={maxDate}
            theme={{
                monthTextColor: Color.ALMOST_BLACK,
                textMonthFontFamily: Platform.OS === 'ios' ? FontFamily.SYSTEM : FontFamily.ROBOTO_REGULAR,
                textMonthFontWeight: '500',
                textMonthFontSize: 14,
                textSectionTitleColor: Color.ALMOST_BLACK
            }}
            dayComponent={renderDayComponent}
            pastScrollRange={0}
            futureScrollRange={4}
            current={selectedDate}
            firstDay={1}
        />
    );
};
