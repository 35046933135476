import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useLayoutEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Text } from '~/components/text';
import { LoadingContent } from '~/components/loader';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useCalendarEventList } from '~/hooks/calendar-event-list';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { UpcomingAppointments } from '../home/upcoming-appointments';
import { CalendarEvent } from '~/types';
import { ChevronLeftIcon, Flex, useBreakpointValue } from 'native-base';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Divider } from '~/components/divider';

type CalendarEventsNavigation = StackNavigationProp<MainNavigatorParamList, 'calendar-events'>;

const NoContentContainer = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export function CalendarEvents() {
    const { calendarEvents, loading, error } = useCalendarEventList();
    const { calendarEvents: pastCalendarEvents, loading: pastLoading, error: pastError } = useCalendarEventList(true);
    useErrorHandler(error);
    useErrorHandler(pastError);

    const { goBack, navigate, setOptions } = useNavigation<CalendarEventsNavigation>();
    const back = useCallback(() => goBack(), [goBack]);
    const { formatMessage } = useIntl();

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.calendar-events.title') }));

    const handleOpenCalendarEvent = useCallback(
        (calendarEvent: CalendarEvent) => navigate('appointment', { calendarEventId: calendarEvent.id }),
        [navigate]
    );

    return (
        <ScreenContainer>
            <ContentHorizontalMargins contentWidth="midsize" includePadding={webComponent}>
                <Flex flexDir="column" grow={1} paddingTop={4} paddingLeft={4} paddingRight={4}>
                    {/* Breadcrumbs for Web, temporarily 'go back' */}
                    {webComponent && (
                        <Flex paddingBottom="4">
                            <Button
                                leftIcon={ChevronLeftIcon}
                                size="small"
                                type="text"
                                label={formatMessage('goBack').toUpperCase()}
                                style={{ maxWidth: 140 }}
                                onPress={() => back()}
                            />
                        </Flex>
                    )}
                    {loading && <LoadingContent />}
                    {calendarEvents && calendarEvents.length > 0 && (
                        <Flex>
                            {webComponent && <Divider leftInset={0} before={0} after={Spacing.MEDIUM} />}
                            <MessageHeaderContainer>
                                <Text.HEADER_3>
                                    <FormattedMessage id="appointments.title" />
                                </Text.HEADER_3>
                            </MessageHeaderContainer>
                            <UpcomingAppointments
                                handleOpenCalendarEvent={handleOpenCalendarEvent}
                                calendarEvents={calendarEvents}
                            />
                        </Flex>
                    )}
                    {pastLoading && <LoadingContent />}
                    {pastCalendarEvents && pastCalendarEvents.length > 0 && (
                        <Flex>
                            {webComponent && <Divider leftInset={0} before={0} after={Spacing.MEDIUM} />}
                            <MessageHeaderContainer>
                                <Text.HEADER_3>
                                    <FormattedMessage id="appointments.past" />
                                </Text.HEADER_3>
                            </MessageHeaderContainer>
                            <UpcomingAppointments calendarEvents={pastCalendarEvents} isDisabled />
                        </Flex>
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </ScreenContainer>
    );
}

const MessageHeaderContainer = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${Spacing.MEDIUM}px;
`;
