import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const ImageAttachmentIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M22 19V5C22 3.89543 21.1046 3 20 3H19.5L11.5 3.00076L4.00007 3.00076C2.89547 3.00076 2.00003 3.89623 2.00007 5.00083L2.00056 19.0001C2.0006 20.1046 2.89602 21 4.00056 21H20C21.1046 21 22 20.1046 22 19Z"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={Color.TRANSPARENT}
                    d="M9.01548 7.00852C7.91095 7.10955 7.00861 8.08295 7.00006 9.18267C6.99151 10.2824 7.87998 11.092 8.98452 10.991C10.0891 10.8899 10.9914 9.91653 10.9999 8.81681C11.0085 7.71709 10.12 6.90749 9.01548 7.00852Z"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    strokeWidth="2"
                />
                <Path
                    d="M18.2929 13.7071C18.6834 14.0976 19.3166 14.0976 19.7071 13.7071C20.0976 13.3166 20.0976 12.6834 19.7071 12.2929L18.2929 13.7071ZM16.2984 11.5846L16.9386 12.3528L16.2984 11.5846ZM17.6457 11.6457L18.3528 10.9386L17.6457 11.6457ZM11.7016 15.4154L11.0614 14.6472L11.7016 15.4154ZM5.6 17.8L8.90616 15.3204L7.70616 13.7204L4.4 16.2L5.6 17.8ZM8.90616 15.3204L9.64715 16.0614L11.0614 14.6472L10.3204 13.9062L8.90616 15.3204ZM12.3417 16.1836L16.9386 12.3528L15.6583 10.8164L11.0614 14.6472L12.3417 16.1836ZM16.9386 12.3528L18.2929 13.7071L19.7071 12.2929L18.3528 10.9386L16.9386 12.3528ZM16.9386 12.3528V12.3528L18.3528 10.9386C17.6214 10.2072 16.4529 10.1542 15.6583 10.8164L16.9386 12.3528ZM9.64715 16.0614C10.3786 16.7928 11.5471 16.8458 12.3417 16.1836L11.0614 14.6472L11.0614 14.6472L9.64715 16.0614ZM8.90616 15.3204L10.3204 13.9062C9.61659 13.2024 8.5024 13.1232 7.70616 13.7204L8.90616 15.3204Z"
                    fill={fill ?? Color.ALMOST_BLACK}
                />
            </Svg>
        </View>
    );
};
