import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ActivityIndicator, Text } from 'react-native';

import { ErrorModal } from '~/components/error';
import { List, ListItem } from '~/components/list';
import { ScreenContainer } from '~/components/screen';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { ChatNode, ChatsDocument } from '~/types';
import { relayConnectionReduce } from '~/utils';

type ChatsNavigation = StackNavigationProp<MainNavigatorParamList, 'chats'>;

export const Chats: FunctionComponent = () => {
    const { data, loading, error, refetch } = useQuery(ChatsDocument, { fetchPolicy: 'network-only' });
    const { goBack, navigate } = useNavigation<ChatsNavigation>();
    useErrorHandler(error);

    const chats = relayConnectionReduce<Pick<ChatNode, 'id' | 'title' | 'type' | 'lastMessageAt'>>(data?.root?.chats);

    if (error) {
        return <ErrorModal error={error} onRetry={refetch} onClose={goBack} />;
    }

    return (
        <ScreenContainer>
            {loading ? (
                <ActivityIndicator />
            ) : (
                <List
                    data={chats ?? []}
                    renderItem={({ item }) => (
                        <ListItem title={item.title} onPress={() => navigate('chat', { chatId: item.id })} />
                    )}
                    ListEmptyComponent={<Text>No chats</Text>}
                />
            )}
        </ScreenContainer>
    );
};
