import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useLayoutEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { WebView, WebViewNavigation } from 'react-native-webview';

import { useAppConfig } from '~/contexts/app-config';
import { AppError } from '~/error';
import { useRoute } from '~/hooks/route/route';
import { AuthenticatedNavigatorParamList } from '~/navigator/app-navigator';

type ExternalPageNavigation = StackNavigationProp<AuthenticatedNavigatorParamList, 'external-page'>;

export type ExternalPageRouteProp = RouteProp<AuthenticatedNavigatorParamList, 'external-page'>;

export function ExternalPage() {
    const { setOptions, goBack } = useNavigation<ExternalPageNavigation>();
    const { path, params } = useRoute<ExternalPageRouteProp>();
    const { config } = useAppConfig();
    const handleError = useErrorHandler();

    useLayoutEffect(() => {
        setOptions({
            headerTitle: ''
        });
    });

    if (!params?.url && (!path || path === '')) {
        handleError(
            new AppError(new Error(`Invalid target`), 'external-page.invalid-target', {
                name: 'error-overlay.go_back',
                onClose: goBack
            })
        );
    }

    const uri = params?.url ?? `${config.http}://${config.host}/${path}`;

    const handleWebViewNavigationStateChange = (newNavState: WebViewNavigation) => {
        if (newNavState.url.includes('isFinished=1')) {
            goBack();
        }
    };

    return (
        <WebView
            startInLoadingState
            source={{ uri }}
            javaScriptEnabled
            onNavigationStateChange={handleWebViewNavigationStateChange}
        />
    );
}
