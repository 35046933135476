import React from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';

import { Color } from '~/components/color';

import { IconProps } from './icon-types';

export const CameraIcon = ({ fill, size = 24 }: IconProps) => {
    return (
        <View style={{ width: size, height: size }}>
            {/* @ts-ignore see https://github.com/react-native-svg/react-native-svg/issues/1471 */}
            <Svg accessibilityRole="image" viewBox="0 0 24 24" focusable={false} tabIndex="-1">
                <Path
                    d="M10.8421 2C8.72017 2 7 3.72017 7 5.84211H9C9 4.82474 9.82474 4 10.8421 4V2ZM13.1579 2H10.8421V4H13.1579V2ZM17 5.84211C17 3.72017 15.2798 2 13.1579 2V4C14.1753 4 15 4.82474 15 5.84211H17ZM18 5H16.1537V7H18V5ZM23 10C23 7.23858 20.7614 5 18 5V7C19.6569 7 21 8.34315 21 10H23ZM23 17V10H21V17H23ZM18 22C20.7614 22 23 19.7614 23 17H21C21 18.6569 19.6569 20 18 20V22ZM6 22H18V20H6V22ZM1 17C1 19.7614 3.23858 22 6 22V20C4.34315 20 3 18.6569 3 17H1ZM1 10V17H3V10H1ZM6 5C3.23858 5 1 7.23858 1 10H3C3 8.34315 4.34315 7 6 7V5ZM7.84634 5H6V7H7.84634V5ZM7.84634 7C8.51946 7 9 6.44452 9 5.84211H7C7 5.41132 7.34484 5 7.84634 5V7ZM15 5.84211C15 6.44452 15.4805 7 16.1537 7V5C16.6552 5 17 5.41132 17 5.84211H15Z"
                    fill={fill ?? Color.ALMOST_BLACK}
                />
                <Path
                    d="M15.7779 13.9016C15.0537 16.0534 12.7753 17.3941 10.6889 16.8961C8.60241 16.3982 7.49802 14.2501 8.22214 12.0984C8.94627 9.94659 11.2247 8.6059 13.3111 9.10385C15.3976 9.60181 16.502 11.7499 15.7779 13.9016Z"
                    stroke={fill ?? Color.ALMOST_BLACK}
                    fill={Color.TRANSPARENT}
                    strokeWidth="2"
                />
            </Svg>
        </View>
    );
};
