export const en = {
    ok: 'OK',
    cancel: 'Cancel',
    close: 'Close',
    edit: 'Edit',
    select: 'Select',
    start: 'Start',
    'start-again': 'Start again',
    next: 'Next',
    previous: 'Previous',
    goBack: 'Go back',
    goBackToStart: 'Go back to Start',
    'continue-later': 'Continue later',
    error: 'Error',
    loading: 'Loading',
    show: 'Show',
    'time.duration': '{duration}min',
    'time.empty': '–',
    'time.at': 'at',
    'time.now': 'now',
    'time.minutes_ago': '{minutes}min ago',
    'time.hours_ago': '{hours}h ago',
    'time.yesterday': 'yesterday',
    'error.close.reset': 'Start again',
    'error.cannot-load-member-actions': 'Error loading available contact options',
    'error.cannot-add-message': 'Sending message failed',
    'error.cannot-load-messages': 'Loading messages failed',
    'error.cannot-download-attachment': 'Downloading attachment failed',
    'error.cannot-create-chat': 'Cannot create chat',
    'error.chat-attachment-upload-failed': 'Loading attachment failed',
    'error.make-appointment-failed': 'Appointment reservation failed',
    'error.cancel-appointment-failed': 'Appointment cancellation failed',
    'error.modify-appointment-failed': 'Appointment modification failed',
    'error.creating-token-failed': 'Creating token failed',
    'error.deleting-token-failed': 'Deleting token failed',
    'network-state.poor-connection': 'Poor network connectivity',
    'auth-intro.referrals': 'Download Referral',
    'auth-intro.title': 'Welcome to MyHeltti!',
    'auth-intro.description': 'Welcome to MyHeltti.',
    'auth-intro.button.title': 'Next',
    'auth-verification-check.title': 'Hello!',
    'auth-verification-check.description': 'Please enter your work email?',
    'auth-verification-check.placeholder': 'Enter work email',
    'auth-verification-check.error': 'An error occurred while performing email verification',
    'auth-authenticate.title': 'Authenticate',
    'auth-authenticate.description':
        'To guarantee secure transactions, you must identify yourself with bank credentials or a mobile certificate.',
    'auth-authenticate.button.title': 'Authenticate',
    'auth-accept-terms-upon-continue':
        "By continuing you'll accept MyHeltti <terms>terms of use</terms> and <privacy>privacy policy</privacy>",
    'auth-accept-terms.terms': 'https://heltti.fi/privacy',
    'auth-accept-terms.privacy': 'https://heltti.fi/privacy',
    'auth-email-signup-intro.title': 'Welcome!',
    'auth-email-signup-intro.description': "Let's create a Heltti account for you.",
    'auth-email-signup-form.title': 'Create a new Heltti account',
    'auth-email-signup-form.submit': 'Create Heltti-account',
    'auth-email-signup-form.error.description': "Something didn't quite go as planned, please try again.",
    'email-change-form.changed.description':
        'You’ll shortly receive a verification email. Please open the link in the email to finalise the change.',
    'auth-pin-authenticate.lead': 'Sign in with PIN code',
    'auth-pin-authenticate.forgotten-pin': 'I have forgotten my PIN code',
    'auth-pin-authenticate.too-many-retries.title': 'Too many retries',
    'auth-pin-authenticate.too-many-retries.description':
        'It seems that you have forgotten your PIN code. Do you want to authenticate in again?',
    'auth-pin-authenticate.authenticate': 'Authenticate',
    'auth-pin-authenticate.other-error.title': 'Error',
    'auth-pin-authenticate.other-error.description':
        'An error occurred during PIN code authentication. Please sign in again.',
    'auth-pin-authenticate.recoverable-error.title': 'Error',
    'auth-pin-authenticate.recoverable-error.description':
        'An error occurred during PIN code authentication, please try again',
    'auth-pin-authenticate.biometric.prompt': 'Open MyHeltti with fingerprint detection',
    'auth-pin-authenticate.biometric.cancel': 'Cancel',
    'auth-pin-authenticate.biometric.fallback': 'Use passcode',
    'auth-pin-authenticate.other-error.authenticate': 'Authenticate',
    'auth-sms-authenticate.enter-code': 'Please enter the verification code from the SMS message',
    'auth-sms-authenticate.invalid-code-retry': 'The code seems not to be valid, please try again.',
    'auth-sms-authenticate.invalid-code-restart.title': 'Too many retries',
    'auth-sms-authenticate.invalid-code-restart.description':
        'It seems the codes are not valid. Please start over and try again.',
    'auth-sms-authenticate.resend-code': 'Send a new SMS',
    'auth-sms-authenticate.resend-code.error': 'Sending a new SMS failed, please try again.',
    'auth-sms-authenticate.resend-code.success': "A new SMS is on it's way!",
    'auth-sms-authenticate.verifying-code': 'Just a second, the code is being verified!',
    'auth-verify.title': 'Verification',
    'auth-verify.error.description': 'Verification service failed to load, please try again later.',
    'auth-verify.in-progress': 'One moment...',
    'auth-verify.success': 'Account verified!',
    'auth-verify.failure': 'Account verification failed!',
    'auth-verification-email-sent.title': 'Hi there!',
    'auth-verification-email-sent.description':
        'If your e-mail address was found in our system, we have sent you a login link to your e-mail. Go and have a look!\n\nPlease note: The login link will only work when opened on this device.',
    'auth-verification-email-sent.helper':
        'If you do not receive an email or text message, contact us at <link>Heltti support</link>',
    'auth-verification-email-sent.support.url':
        'mailto:support@heltti.fi?subject=Error&body=Error:%20email%20verification%20failed',
    'auth-logout.failure': 'Could not sign out. Please try again.',
    'app-update.required.title': 'MyHeltti is better than ever!',
    'app-update.required.description':
        'Support for the current version has expired and MyHeltti must be upgraded to continue using it.',
    'app-update.required.ota': 'Update here',
    'app-update.required.app-store':
        'Update in the {platform, select, ios {AppStore} android {Google Play Store} other {}}',
    'app-update.required.support': 'Need help? Contact <link>Heltti support</link>',
    'app-update.required.support.url': 'mailto:support@heltti.fi?subject=Error&body=Error:%20app update failed',
    'app-update.optional.title': 'MyHeltti update available',
    'app-update.optional.description': 'Update MyHeltti to the latest version by pressing Update now -button.',
    'app-update.optional.submit': 'Update now',
    'app-update.complete.title': 'Update complete',
    'app-update.complete.description': 'MyHeltti has been updated to the latest version. The application will restart.',
    'member-action.faq.title': 'Frequently asked questions',
    'member-action.faq.description': 'Answers to the most frequently asked questions',
    'member-action.sick-leave.title': 'Sick leave',
    'member-action.sick-leave.description': 'Report short absenses from work',
    'new-contact.title': 'New contact',
    'new-contact.non_busy_title': 'Non-acute care\nand wellbeing',
    'new-contact.action.type': '{type, select, CALL {Call} other {Start} }',
    'sick-leave.title': 'Absence due to illness',
    'sick-leave.description': 'Report short absences from work',
    'sick-leave.first-date': 'First day of absence',
    'sick-leave.day-count': 'Number of days',
    'sick-leave.reason': 'Description',
    'sick-leave.submit-confirmation.title': 'Thank you!',
    'sick-leave.submit-confirmation.description': 'Get well soon.',
    'sick-leave.submit-confirmation.continue': 'Continue',
    'sick-leave.submit': 'Send',
    'sick-leave.error.cannot-report': 'Reporting of the absence failed',
    'contact-reason.reason_placeholder': 'Could you tell briefly how we can help?',
    'contact-reason.title_placeholder': 'Title',
    'contact-reason.start_conversation': 'Start conversation',
    'onboarding-pin-setup.hello': 'Hi {name}!',
    'onboarding-pin-setup.instructions':
        'Generate a PIN code to log in. In addition to the PIN code, you can also activate a biometric identifier, such as a fingerprint.',
    'onboarding.enable': 'Enable',
    'onboarding.pass': 'Not now',
    'onboarding.pin-setup-intro.title': 'Hi {callName}!',
    'onboarding.pin-setup-intro.description': 'Login was successful!\n\nNext, a PIN code is created for logging in.',
    'onboarding.pin-setup-intro.button.title': 'Seuraava',
    'onboarding-biometric-auth.hello': 'Hi {name}!',
    'onboarding-biometric-authenticate.description':
        'You can also log in using a biometric identifier. It makes logging in quick and easy.\n\nYou can also enable biometric login later in your phone settings.',
    'onboarding-biometric-auth.error.cannot_enable': 'Activating fingerprint detection failed',
    'onboarding-push-notifications.hello': 'Hi {name}!',
    'onboarding-push-notifications.description':
        'Next, MyHeltti will ask permission to send you notifications. We recommend that you allow notifications so that you can easily stay up-to-date on your care path events.\n\nYou can also turn on notifications later in your phone settings.',
    'onboarding-push-notifications.error.cannot_enable': 'Activating push notifications failed',
    'onboarding-terms.title': 'Communication',
    'onboarding-terms.description': "We'd like to send you an occasional email to keep you up with the latest.",
    'onboarding-terms.newsletters': 'Newsletters',
    'onboarding-terms.accept-communications.label': 'Service updates',
    'onboarding-terms.accept-communications.helper': 'News and information about services relevant to you',
    'onboarding-terms.accept-marketing.label': 'Heltti community',
    'onboarding-terms.accept-marketing.helper': 'Information, inspiration and tips for work and life in general',
    'onboarding-terms.accept-feedback.label': 'Feedback',
    'onboarding-terms.accept-feedback.helper': 'E.g. Satisfaction polls after using our services.',
    'onboarding-profile.title': 'Profile check',
    'onboarding-profile.description': 'You can change your information from profile settings view later on.',
    'onboarding-completed.hello': 'Hi {name}!',
    'onboarding-completed.description': 'Everything is now set up and you can start using MyHeltti!',
    'onboarding-completed.completed': 'Ready!',
    'pin-code-setup.enter-pin': 'Create a four-digit PIN code for login.',
    'pin-code-setup.re-enter-pin': 'Re-enter PIN code.',
    'pin-code-setup.pin-mismatch': 'The PIN codes do not match. Enter\nthe PIN code again.',
    'pin-code-setup.pin-set': 'PIN code set.',
    'pin-code-validate.enter-pin': 'Enter PIN code.',
    'pin-code-validate.biometric-auth-failed': 'Access to PIN code denied, please try again.',
    'pin-code-validate.valid-pin': 'Valid PIN code.',
    'pin-code-validate.invalid-pin': 'Invalid PIN code, please try again.',
    'error.alert.title': 'Error',
    'error.alert.generic': 'Operation failed. Please try again.',
    'error-overlay.title': 'Oopsie!',
    'error-overlay.description': 'An unexpected error occurred!',
    'error-overlay.support': 'Contact <link>Heltti support</link>.',
    'error-overlay.support.url': 'mailto:support@heltti.fi?subject=Error&body=Error:%20{id}',
    'error-overlay.error_id': 'Error: {id}',
    'error-overlay.retry': 'Retry',
    'error-overlay.close': 'Cancel',
    'error-overlay.go_back': 'Go back',
    'modal-picker.back': 'Go back',
    'user-profile.error.cannot_load': 'Cannot load user profile',
    'tab-bar.home': 'Home',
    'tab-bar.library': 'Library',
    'tab-bar.me': 'Me',
    'tab-bar.services': 'Services',
    'home.ctas': 'For you',
    'home.no-chats': 'No active discussions',
    'home.all-ctas': 'All services',
    'home.all-chats': 'All messages',
    'home.all-appointments': 'All appointments',
    'appointments.title': 'Upcoming appointments',
    'appointments.past': 'Past appointments',
    'chats.title': 'Chats',
    'chat.active_tasks': 'Active tasks',
    'chat.load_more': 'Load more messages',
    'chat.participants': 'Participants',
    'chat-input.placeholder': 'Write message',
    'chat-message.show_more': 'Read more',
    'chat-message.show_less': 'Show less',
    'chat-message.joined_chat': 'Joined the chat',
    'chat-message.left_chat': 'left the chat',
    'chat-message.chat_closed': '{timestamp} Chat closed',
    'chat-message.chat_reopened': '{timestamp} Chat reopened',
    'chat-message.chat_escalated': '{timestamp} A nurse has been called. Just a moment, please!',
    'chat-message.new_messages': 'New messages',
    'chat-attachment.error.load-failed': 'Attachment failed to load.',
    'chat-notification-action.label.call': 'Call HelttiLinja',
    'chat-notification-action.label.escalate': 'Call for nurse',
    'nps.title': 'How well did we succeed',
    'nps.detail1.company.title': 'How valuable did you find our latest meeting?',
    'nps.detail1.company.max-description': 'Very valuable',
    'nps.detail1.member.title': 'Do you feel like you were heard?',
    'nps.detail1.mindy.title': 'How useful were the Mindy-coaching sessions?',
    'nps.detail1.mindy.max-description': 'Very valuable',
    'nps.detail1.min-description': 'Not at all',
    'nps.detail1.max-description': 'Very well',
    'nps.detail2.company.title': 'How satisfied are you with our cooperation overall?',
    'nps.detail2.company.max-description': 'Very satisfied',
    'nps.detail2.member.title': 'Did you get the help you needed',
    'nps.detail2.min-description': 'Not at all',
    'nps.detail2.max-description': 'Very well',
    'nps.nps-question.title': 'How likely would you recommend Heltti to your friends or colleagues?',
    'nps.nps-question.mindy.title': 'How likely would you recommend Mindy to your friends or colleagues?',
    'nps.nps-question.min-description': 'Not likely',
    'nps.nps-question.max-description': 'Very likely',
    'nps.comment.title': 'Please specify why',
    'nps.contact-permission.title': 'I can be contacted regarding this feedback',
    'nps.send': 'Send',
    'nps.data-protection-description': 'Data protection description',
    'nps.thank-you': 'Thank you!',
    'profile.title': 'My profile',
    'profile.full-name': 'Name',
    'profile.call-name': 'Nickname',
    'profile.first-name': 'First name',
    'profile.last-name': 'Last name',
    'profile.ssn': 'Social security number',
    'profile.image.edit': 'Edit profile picture',
    'profile.identification.helper': 'The information has been obtained through identification.',
    'profile.contact-info.helper': 'We primarily use this information when communicating with you.',
    'profile.email': 'Email',
    'profile.phone': 'Phone',
    'profile.contact-details': 'Contact details',
    'profile.private-email': 'Email (private)',
    'profile.private-phone': 'Phone (private)',
    'profile.address1': 'Address 1',
    'profile.address2': 'Address 2',
    'profile.zip': 'Zip code',
    'profile.city': 'City',
    'profile.work-header': 'Information provided by the employer',
    'profile.work-postfix': '(work)',
    'profile.work-employer': 'Employer',
    'profile.work-email': 'Work email',
    'profile.work-phone': 'Work phone',
    'profile.work-info':
        'The information provided by the employer cannot be edited. Contact your employer to change this information.',
    'profile.permissions': 'Permissions',
    'profile.language': 'Application & service language',
    'profile.language.fi': 'Finnish',
    'profile.language.en': 'English',
    'profile.verify': 'Update name',
    'profile.verify.description': 'Authenticate to update your name',
    'profile.verify.authenticate': 'Authenticate',
    'profile.verify.go_back': 'Go back',
    'profile.account-removal.title': 'Request account removal',
    'profile.account-removal.description':
        'We remove your information from our registeries upon request. Please take into account that we have a obligation to keep some of the information we store, e.g. information in our patient registry. Our data security policy is available at https://heltti.fi/tietosuoja.',
    'profile.account-removal.additional-information': 'Additional information',
    'profile.account-removal.submit': 'Request removal',
    'profile.account-removal.success': 'Request sent',
    'profile.calendar-events.title': 'Appointments',
    'profile.calendar-events.description': 'View your appointments',
    'profile.calendar-events.none': 'No appointments',
    'profile.calendar-reservation-accesses.title': 'Open appointment reservations',
    'profile.calendar-reservation-accesses.description': 'View your open appointment reservations',
    'profile.calendar-reservation-accesses.item.title': 'Reserve an appointment',
    'profile.calendar-reservation-accesses.none': 'No open appointment reservations',
    'profile.documents.title': 'Documents',
    'profile.documents.description': 'View your documents',
    'profile.documents.none': 'No documents',
    'profile.document.added-date': 'Added',
    'profile.document.comment': 'Message',
    'profile.document.export-tokens.title': 'Download codes',
    'profile.document.export-tokens.code': 'Code',
    'profile.document.export-tokens.name': 'Name',
    'profile.document.export-tokens.valid-until': 'Valid until',
    'profile.document.export-tokens.create-token': 'Create token',
    'profile.document.export-tokens.delete-token': 'Delete token',
    'profile.document.filename': 'Name',
    'profile.document.open': 'Open document',
    'profile.document.title': 'Document',
    'profile.document.export-tokens.help':
        "Download codes make it easy to pass referrals for our partner clinics.\n\nThe partner clinic's reception can download this document from the **myheltti.fi/referrals** page by entering your last name and the download code shown here.",
    'profile.questionnaires.title': 'Questionnaires',
    'profile.questionnaires.description': 'View your questionnaires',
    'profile.questionnaires.none': 'No questionnaires',
    'profile.sign-out': 'Sign out',
    'profile.error.image-upload-failed': 'Profile image upload failed',
    'questionnaire-response-batch.title': 'Questionnaires {date, date, ::dd.MM.yyyy}',
    'questionnaire-response-batch.open-until': 'Open until {date, date, ::dd.MM.yyyy}.',
    'questionnaire-response-batch.closed-at': 'Closed on {date, date, ::dd.MM.yyyy}.',
    'form.validate-phone.error.INVALID_COUNTRY': 'Invalid country code',
    'form.validate-phone.error.NOT_A_NUMBER': 'Not a number',
    'form.validate-phone.error.TOO_SHORT': 'Phone number too short',
    'form.validate-phone.error.TOO_LONG': 'Phone number too long',
    'form.validate-phone.error.INVALID_LENGTH': 'Invalid phone number',
    'form.validate-email.error': 'Invalid email address',
    'form.validate-number.error': 'Enter numbers only',
    'form.flash-saving': 'Saving...',
    'form.flash-success': 'Saved',
    'form.flash-failure': 'Save failed',
    'appointments.abort': 'Abort appointment reservation',
    'appointments.all-locations': 'All locations',
    'appointments.next-available-time': 'Next available time {time}',
    'appointments.remote': 'remote',
    'appointments.ALL': 'All',
    'appointments.MORNING': 'Morning',
    'appointments.AFTERNOON': 'Afternoon',
    'appointments.select-location': 'Select location',
    'appointments.select-region.remote-only': 'Remote only',
    'appointments.select-region': 'Select region',
    'appointments.select-region.locate.title': 'Locate the nearest service point',
    'appointments.select-region.locate.description':
        'By allowing MyHeltti to use your location,\nyou can easily find the nearest service point.',
    'appointments.select-region.choose.title': 'Please select an area\nto look for free appointments',
    'appointments.select-region.choose.description':
        "You can also allow MyHeltti to use your\ncurrent location. Change location settings from\nyour phone's privacy settings.",
    'appointments.confirmation.title': 'Confirm appointment',
    'appointments.day.previous': 'Previous day',
    'appointments.day.next': 'Next day',
    'appointments.no-appointments-available': 'No available appointments',
    'appointments.error.not-available': 'Appointment reservation system is not available, please try again later.',
    'appointments.error.cannot-load-appointments': 'Error loading appointments.\nPlease try again.',
    'appointments.error.try-again': 'Try again',
    'appointment.task':
        '{taskType, select, VIEW_APPOINTMENT {Appointment} VIEW_CANCELLATION {Cancellation} other {Reservation} }',
    'appointments.make-appointment':
        'Make {type, select, phone {phone appointment} video {video appointment} other {appointment} }',
    'appointments.change-appointment': 'Change appointment',
    'appointment.read-cancellation-terms': 'Read the cancellation policy',
    'appointment.cancellation-terms': 'Read the cancellation policy <u>here</u>',
    'appointment.type':
        '{type, select, PHONE {Phone} APPOINTMENT_PHONE {Phone} VIDEO {Video} APPOINTMENT_VIDEO {Video} other {Clinic visit} }',
    'appointment.contact-info.title':
        'Instructions for {type, select, PHONE {phone appointment} APPOINTMENT_PHONE {phone appointment} VIDEO {video appointment} APPOINTMENT_VIDEO {video appointment} other {appointment} }',
    'appointment.contact-info.description':
        '{type, select, PHONE {We will call to the phone number you have given in your profile.} APPOINTMENT_PHONE {We will call to the phone number you have given in your profile.} VIDEO {Ensure that you have a working camera and microphone for the video appointment.} APPOINTMENT_VIDEO {Ensure that you have a working camera and microphone for the video appointment.} other {} }',
    'appointment.title': 'Upcoming event',
    'appointment-confirmed.title': 'Appointment confirmed',
    'appointment-confirmed.close': 'Ok!',
    'appointment-cancelled.title': 'Cancelled event',
    'appointment.join-video-call': 'Join video call',
    'appointment.video-call-details': 'Video call link will be available 10 min before the start of the appointment',
    'appointment.add-calendar': 'Add to calendar',
    'appointment.add-calendar.title': 'Add appointment to a calendar',
    'appointment.add-calendar.description': 'Choose calendar to add the appointment to',
    'appointment.add-calendar.event.title':
        '{calendarEventType, select, APPOINTMENT_PHONE {Phone appointment} APPOINTMENT_VIDEO {Video appointment} other {Clinic visit}}',
    'appointment.select-type': 'Select appointment type',
    'appointment.add-calendar.no-calendars': 'No calendars available',
    'appointment.modify': 'Change appointment',
    'appointment.modify-or-cancel': 'Modify or cancel appointment',
    'appointment.modify.title':
        '{modifiable, select, true {Are you sure you want to change your appointment?} other {Changing appointment is no longer possible}}',
    'appointment.modify.description':
        '{modifiable, select, true {Changes to the booked time must be made 24 hours before the start of the reception time. Read more detailed terms <link>here</link>.} other {The start of your appointment is less than 24h away. You can still cancel, but please note\nthe <link>cancellation policy</link>.}}',
    'appointment.modify.cancellation-hours': 'The appointment time can be changed 24 hours prior to appointment.',
    'appointment.modify.new-appointment-after-cancellation':
        'Please contact us if you want to book a new appointment after cancellation.',
    'appointment.cancel': 'Cancel appointment',
    'appointment.cancel.title': 'Are you sure you want to cancel your appointment?',
    'appointment.cancel.description':
        'Changes to the booked time must be made 24 hours before the start of the reception time. Read more detailed terms <link>here</link>.',
    'appointment.filter': 'Filter Appointments',
    'appointment.type.label': 'Appointment type',
    'appointment.type.VISIT': 'Appointment',
    'appointment.type.VIDEO': 'Video appointment',
    'appointment.type.PHONE': 'Phone appointment',
    'appointment.type.CHAT': 'Chat',
    'appointment.type.OTHER': 'Other',
    'appointment.language.label': 'Expert language skills',
    'appointment.language.FI': 'finnish',
    'appointment.language.SV': 'swedish',
    'appointment.language.EN': 'english',
    'appointment.gender.label': 'Expert gender',
    'appointment.gender.OTHER': 'Other',
    'appointment.gender.FEMALE': 'Female',
    'appointment.gender.MALE': 'Male',
    'appointment.gender.ANY': 'Any',
    'appointment.age.label': 'Expert age',
    'appointment.age.UNDER_30': 'Under 30 years',
    'appointment.age.BETWEEN_30_45': '30-45 years',
    'appointment.age.BETWEEN_46_59': '46-59 years',
    'appointment.age.OVER_60': 'Over 60 years',
    'appointment.age.ANY': 'Any',
    'video-call.title': 'Video appointment',
    'calendar.month.january': 'January',
    'calendar.month.february': 'February',
    'calendar.month.march': 'March',
    'calendar.month.april': 'April',
    'calendar.month.may': 'May',
    'calendar.month.june': 'June',
    'calendar.month.july': 'July',
    'calendar.month.august': 'August',
    'calendar.month.september': 'September',
    'calendar.month.october': 'October',
    'calendar.month.november': 'November',
    'calendar.month.december': 'December',
    'calendar.month.january.short': 'Jan',
    'calendar.month.february.short': 'Feb',
    'calendar.month.march.short': 'March',
    'calendar.month.april.short': 'Apr',
    'calendar.month.may.short': 'May',
    'calendar.month.june.short': 'Jun',
    'calendar.month.july.short': 'Jul',
    'calendar.month.august.short': 'Aug',
    'calendar.month.september.short': 'Sep',
    'calendar.month.october.short': 'Oct',
    'calendar.month.november.short': 'Nov',
    'calendar.month.december.short': 'Dec',
    'calendar.day.monday': 'Monday',
    'calendar.day.tuesday': 'Tuesday',
    'calendar.day.wednesday': 'Wednesday',
    'calendar.day.thursday': 'Thursday',
    'calendar.day.friday': 'Friday',
    'calendar.day.saturday': 'Saturday',
    'calendar.day.sunday': 'Sunday',
    'calendar.day.monday.short': 'MON',
    'calendar.day.tuesday.short': 'TUE',
    'calendar.day.wednesday.short': 'WED',
    'calendar.day.thursday.short': 'THU',
    'calendar.day.friday.short': 'FRI',
    'calendar.day.saturday.short': 'SAT',
    'calendar.day.sunday.short': 'SUN',
    'calendar.day.today': 'Today',
    'calendar.pick.date': 'Pick date',
    'attachment.type_description': '{type, select, IMAGE {Image} FILE {Attachment} other {Attachment} }',
    'chat-archive.title': 'Message archive',
    'chat-archive.notification.empty': 'Your message archive is empty',
    'library.title': 'Library',
    'library.description':
        'Here, we have gathered various services, articles and tools to support the well-being of the mind and body.',
    'services.noServicesAvailable': 'No services available',
    'services.title': 'Services',
    'services.description': 'Below you will find all the services connected to your account',
    'services.article.read-more': 'Read more',
    'services.article.loading': 'Loading content...',
    'services.error.article-not-available': 'An error occureed while loading the content',
    'error-modal.header.generic': 'An error occurred',
    'error-modal.header.unrecoverable': 'An error occurred',
    'error-modal.header.connection': 'No internet connection',
    'error-modal.header.auth': 'Authentication',
    'error-modal.connection.title': 'Disconnected',
    'error-modal.connection.info': 'MyHeltti needs a network connection to work properly.',
    'error-modal.connection.info2':
        'Check your network connection and try again.   If the problem persists, contact your service provider.',
    'error-modal.retry': 'Try again',
    'error-modal.generic.title': 'OOPS!',
    'error-modal.generic.info': 'Something went wrong.',
    'error-modal.unrecoverable.title': 'Unrecoverable error',
    'error-modal.unrecoverable.info': 'You need to sign out.',
    'error-modal.auth.title': 'Authentication failed',
    'error-modal.auth.info': 'Please try again. If the problem recurs, contact Heltti support.',
    'external-page.invalid-target': 'Page load failed',
    'email-verify.invalid-token': 'Page load failed',
    'email-verify-completed.hello': 'Hi {name}!',
    'email-verify-completed.closebutton': 'Close',
    'email-verify-completed.loading': 'Processing email verification.',
    'email-verify-completed.success': 'Email verified.',
    'email-verify-failed.title': 'Email verification failed',
    'external-referral.title': 'Download Referral',
    'external-referral.form.last-name': 'Last Name',
    'external-referral.form.code': 'Security Code',
    'external-referral.form.error': 'Oops, the token is not valid. Please try again!',
    'external-referral.form.error.lenght': 'Input too short',
    'external-referral.form.token-valid': 'Please click on the button below to download the referral.',
    'external-referral.button.open': 'Open Referral',
    'external-referral.button.download': 'Download Referral',
    'external-referral.button.clear': 'Clear',
    'external-referral.instructions.clinic.title': 'Instructions for customer service',
    'external-referral.instructions.clinic.description':
        "Open this page with an Internet browser using address **myheltti.fi/referrals**\n\nEnter member's **last name** and **security code** to the form on the left and click **Open referral**.\n\nIf the code is valid, the file can be downloaded using the **Download referral** button. The security code expires once the file is downloaded.",
    'external-referral.instructions.member.title': 'Instructions for member',
    'external-referral.instructions.member.description':
        "After logging in to MyHeltti you can create security codes on the **Documents** page. Just click open the document's details and then the **Create code** button."
};
