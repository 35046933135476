import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { OvalButton } from '~/components/button';
import { CloseIcon } from '~/components/icon';
import { Spacing } from '~/components/spacing';

export type HeaderCloseButtonProps = {
    onPress?: () => void;
};

export const HeaderCloseButton: React.FC<HeaderCloseButtonProps> = props => {
    const { onPress } = props;
    const { goBack } = useNavigation();

    return (
        <View style={{ padding: Spacing.MEDIUM }}>
            <OvalButton size="small" icon={CloseIcon} onPress={onPress ?? goBack} />
        </View>
    );
};
