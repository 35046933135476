import { OperationVariables, TypedDocumentNode } from '@apollo/client';
import {
    LoadingQueryOptions,
    LoadingQueryResult as InnerLoadingQueryResult,
    OnlineStateChangeHandler,
    useCoreLoadingQuery
} from '@heltti/common';
import { DocumentNode } from 'graphql';
import { useCallback, useEffect, useRef } from 'react';

import { useNetworkServiceState } from '~/contexts/network-service-state';
import * as ErrorDiagnostics from '~/error';
import { usePreviousValue } from '~/hooks/previous-value';

export type LoadingQueryResult<TData, TVariables extends OperationVariables> = InnerLoadingQueryResult<
    TData,
    TVariables
>;

export function useLoadingQuery<
    TData,
    TVariables extends OperationVariables = OperationVariables,
    TSubscriptionData = TData,
    TSubscriptionVariables extends OperationVariables = OperationVariables
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: LoadingQueryOptions<TData, TVariables, TSubscriptionData, TSubscriptionVariables>
): LoadingQueryResult<TData, TVariables> {
    const { reachability } = useNetworkServiceState();

    const networkBecameAvailable =
        usePreviousValue(reachability) === 'service-unavailable' && reachability !== 'service-unavailable';
    const networkBecameUnavailable =
        usePreviousValue(reachability) !== 'service-unavailable' && reachability === 'service-unavailable';

    const onlineEventHandler = useRef<OnlineStateChangeHandler | null>(null);

    /**
     * Add online event listener
     */
    const addOnlineEventListener = useCallback((handler: OnlineStateChangeHandler) => {
        onlineEventHandler.current = handler;
    }, []);

    /**
     * Remove online event listener
     */
    const removeOnlineEventListener = useCallback((handler: OnlineStateChangeHandler) => {
        onlineEventHandler.current = null;
    }, []);

    /**
     * Handle online state change
     */
    useEffect(() => {
        if (networkBecameAvailable) {
            onlineEventHandler.current?.(true);
        } else if (networkBecameUnavailable) {
            onlineEventHandler.current?.(false);
        }
    }, [networkBecameAvailable, networkBecameUnavailable]);

    return useCoreLoadingQuery(
        query,
        {
            ...options,
            // Skip query when network is offline
            skip: reachability === 'service-unavailable' ?? options?.skip
        },
        ErrorDiagnostics.error,
        addOnlineEventListener,
        removeOnlineEventListener
    );
}
