import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { decode } from 'base-64';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useLayoutEffect, useMemo } from 'react';

import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { HeaderBackButton } from '~/components/header';
import { HelttiHeaderStackHeaderWrapper } from '~/components/heltti-header';
import { Markdown } from '~/components/markdown';
import { ProfileCardImage } from '~/components/profile-image';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import * as ErrorDiagnostics from '~/error';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { User } from '~/types';

export type StaffProfileRouteProp = RouteProp<MainNavigatorParamList, 'staff-profile'>;

const IMAGE_HEIGHT = 476;

export function StaffProfile() {
    const { params } = useRoute<StaffProfileRouteProp>();
    const { setOptions, goBack } = useNavigation();
    const headerHeight = useHeaderHeight();

    const user = useMemo<User>(() => {
        try {
            return JSON.parse(decode(params.user));
        } catch (error: unknown) {
            ErrorDiagnostics.error(`${error}: ${params.user}`);
            throw error;
        }
    }, [params.user]);

    const { onScroll } = useExtendedNavigationHeader({
        offset: IMAGE_HEIGHT - headerHeight,
        range: 50,
        title: `${user.firstName} ${user.lastName}`,
        headerLeft: <HeaderBackButton />
    });

    const showDesktopComponent: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    useLayoutEffect(() => {
        if (showDesktopComponent) {
            setOptions({
                header: (props: StackHeaderProps) => (
                    <HelttiHeaderStackHeaderWrapper {...props} headerRight={<HeaderBackButton onPress={goBack} />} />
                )
            });
        }
    }, [goBack, setOptions, showDesktopComponent]);

    return (
        <ContentScrollView
            showsVerticalScrollIndicator={false}
            onScroll={onScroll}
            bounces
            scrollEventThrottle={16}
            overScrollMode="never"
        >
            <ContentHorizontalMargins contentWidth="midsize" includePadding={false}>
                <Flex
                    variant="standardFlexResponsive"
                    grow="1"
                    marginTop={{ base: '0px', md: '150px' }}
                    paddingLeft={{ md: '48px' }}
                >
                    <Flex flexBasis={{ md: '440px' }} grow="0">
                        <ProfileCardImage imageUrl={user.avatarBigUrl ? user.avatarBigUrl : undefined} />
                    </Flex>
                    <Flex
                        marginLeft={{ base: '16px', md: '-15px' }}
                        marginTop={{ base: '8px', md: '0px' }}
                        width="100%"
                    >
                        {showDesktopComponent ? (
                            <Text.H2 style={{ color: Color.HEADER, paddingBottom: 16 }}>
                                {`${user.firstName} ${user.lastName}`}
                            </Text.H2>
                        ) : (
                            <Text.H1 style={{ color: Color.HEADER, paddingBottom: 24 }}>
                                {`${user.firstName} ${user.lastName}`}
                            </Text.H1>
                        )}
                        <Text.LEAD_1>{user.title}</Text.LEAD_1>
                        <Spacing.Vertical.LARGE />
                        {user.profile ? <Markdown>{user.profile}</Markdown> : null}
                    </Flex>
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
}
