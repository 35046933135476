import { HeaderBackButton } from '@react-navigation/elements';
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack';
import { Box, Flex, View, useBreakpointValue } from 'native-base';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { TextInput } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { BubbleLines } from '~/components/bubble-lines';
import { Button } from '~/components/button';
import { HelperButton } from '~/components/button/helper-button';
import { CheckBox, CheckGroup } from '~/components/check';
import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import {
    FormEmailInput,
    FormGroup,
    FormNumericTextInput,
    FormPhoneNumberInput,
    FormRadioGroupInput,
    FormTextInput
} from '~/components/form';
import { HelttiHeaderStackHeaderWrapper } from '~/components/heltti-header';
import { ProfileCardImage } from '~/components/profile-image';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useAuth } from '~/contexts/auth';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import { useDynamicHeaderColor } from '~/hooks/dynamic-header-color/dynamic-header-color';
import { useProfileEdit } from '~/hooks/profile-edit';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { LanguageCode, ProfileFieldEditMutationInput } from '~/types';

import { SignOutProps, profileSignOut } from './profile';

type ProfileEditNavigationProp = CompositeNavigationProp<
    StackNavigationProp<MainNavigatorParamList, 'profile-edit'>,
    StackNavigationProp<AppNavigatorParamList>
>;

export type ProfileEditableFields = Pick<
    ProfileFieldEditMutationInput,
    | 'callName'
    | 'firstName'
    | 'lastName'
    | 'privatePhone'
    | 'email'
    | 'workPhone'
    | 'workEmail'
    | 'address1'
    | 'address2'
    | 'city'
    | 'zip'
    | 'lang'
    | 'marketingPermission'
    | 'memberCommunicationPermission'
    | 'feedbackPermission'
>;

export function ProfileEdit() {
    const { navigate, setOptions, goBack } = useNavigation<ProfileEditNavigationProp>();
    const { biometric, remote } = useAuth();
    const {
        id,
        callName,
        firstName,
        lastName,
        ssn,
        email,
        privatePhone,
        address1,
        address2,
        city,
        zip,
        workEmail,
        workPhone,
        lang,
        avatarBigUrl,
        employment,
        marketingPermission,
        memberCommunicationPermission,
        feedbackPermission,
        verificationRequired,
        verified
    } = useUserProfile();

    const showMobileHeader = useBreakpointValue({
        base: true,
        md: false
    });

    useLayoutEffect(() => {
        if (showMobileHeader) {
            setOptions({ headerLeft: () => <HeaderBackButton onPress={goBack} /> });
        } else {
            setOptions({
                header: (props: StackHeaderProps) => (
                    <HelttiHeaderStackHeaderWrapper {...props} headerRight={<HeaderBackButton onPress={goBack} />} />
                )
            });
        }
    }, [goBack, setOptions, showMobileHeader]);

    const { profileFieldUpdate, profileImageUpload } = useProfileEdit(id);
    const { formatMessage } = useIntl();
    const { bottom } = useSafeAreaInsets();

    const [updatedEmail, setUpdatedEmail] = useState(email);

    const callNameRef = useRef<TextInput>(null);
    const firstNameRef = useRef<TextInput>(null);
    const lastNameRef = useRef<TextInput>(null);
    const emailRef = useRef<TextInput>(null);
    const privatePhoneRef = useRef<TextInput>(null);
    const address1Ref = useRef<TextInput>(null);
    const address2Ref = useRef<TextInput>(null);
    const zipRef = useRef<TextInput>(null);
    const cityRef = useRef<TextInput>(null);

    const handleSignOut = useCallback(async () => {
        const logoutFailure = formatMessage('auth-logout.failure');
        const signOutProps: SignOutProps = {
            biometric,
            remote,
            logoutFailure
        };

        await profileSignOut(signOutProps);
    }, [biometric, remote, formatMessage]);

    const handleAccountRemoval = () => {
        navigate('account-removal');
    };

    const { onScroll } = useDynamicHeaderColor({
        range: 40,
        color: { from: Color.BACKGROUND_PRIMARY, to: Color.BACKGROUND_DEFAULT },
        title: formatMessage('profile.title')
    });

    return (
        <ContentScrollView
            showsVerticalScrollIndicator={false}
            onScroll={onScroll}
            bounces
            scrollEventThrottle={16}
            overScrollMode="never"
        >
            <ContentHorizontalMargins contentWidth="midsize" includePadding={false}>
                <Flex variant="standardFlexResponsive" grow="1" shrink="1" marginTop={{ base: '0px', md: '80px' }}>
                    <ProfileCardImage
                        onImageSelected={profileImageUpload}
                        imageUrl={avatarBigUrl ? avatarBigUrl : undefined}
                        editable
                    />
                    <Flex
                        flexGrow={2}
                        shrink="1"
                        margin={{ base: '8px 24px', md: '0px 0px' }}
                        marginBottom={bottom + 8}
                    >
                        <FormGroup>
                            <FormTextInput
                                ref={callNameRef}
                                caption={formatMessage('profile.call-name')}
                                initialValue={callName}
                                textContentType="givenName"
                                testID="Nickname"
                                autoComplete="name"
                                returnKeyType="next"
                                onSubmitEditing={() => callNameRef.current?.focus()}
                                onSubmit={value => profileFieldUpdate({ callName: value })}
                            />
                            {verified || verificationRequired ? (
                                <FormTextInput
                                    disabled
                                    caption={formatMessage('profile.full-name')}
                                    initialValue={`${firstName} ${lastName}`}
                                />
                            ) : (
                                <>
                                    <FormTextInput
                                        ref={firstNameRef}
                                        testID="firstNameField"
                                        caption={formatMessage('profile.first-name')}
                                        initialValue={firstName}
                                        textContentType="name"
                                        autoComplete="name"
                                        returnKeyType="next"
                                        onSubmitEditing={() => firstNameRef.current?.focus()}
                                        onSubmit={value => profileFieldUpdate({ firstName: value })}
                                    />
                                    <FormTextInput
                                        ref={lastNameRef}
                                        caption={formatMessage('profile.last-name')}
                                        initialValue={lastName}
                                        textContentType="familyName"
                                        autoComplete="name"
                                        returnKeyType="next"
                                        onSubmitEditing={() => lastNameRef.current?.focus()}
                                        onSubmit={value => profileFieldUpdate({ lastName: value })}
                                    />
                                </>
                            )}
                            {ssn ? (
                                <FormTextInput disabled caption={formatMessage('profile.ssn')} initialValue={ssn} />
                            ) : null}
                        </FormGroup>
                        {verified && <ProfileEditUpdateName />}
                        <FormGroup caption={formatMessage('profile.contact-details')}>
                            <FormEmailInput
                                ref={emailRef}
                                caption={formatMessage('profile.email')}
                                testID="Email"
                                initialValue={email ?? null}
                                returnKeyType="next"
                                onSubmitEditing={() => privatePhoneRef.current?.focus()}
                                onSubmit={value => {
                                    setUpdatedEmail(value);
                                    return profileFieldUpdate({ email: value });
                                }}
                            />
                            {email !== updatedEmail && (
                                <HelperButton title={formatMessage('email-change-form.changed.description')} />
                            )}

                            <FormPhoneNumberInput
                                ref={privatePhoneRef}
                                caption={formatMessage('profile.phone')}
                                initialValue={privatePhone ?? null}
                                testID="Phone"
                                returnKeyType="next"
                                onSubmitEditing={() => address1Ref.current?.focus()}
                                onSubmit={value => profileFieldUpdate({ privatePhone: value })}
                            />
                            <FormTextInput
                                ref={address1Ref}
                                caption={formatMessage('profile.address1')}
                                initialValue={address1 ?? null}
                                testID="Address1"
                                textContentType="fullStreetAddress"
                                autoComplete="street-address"
                                returnKeyType="next"
                                onSubmitEditing={() => address2Ref.current?.focus()}
                                onSubmit={value => profileFieldUpdate({ address1: value })}
                            />
                            <FormTextInput
                                ref={address2Ref}
                                caption={formatMessage('profile.address2')}
                                initialValue={address2 ?? null}
                                testID="Address2"
                                textContentType="fullStreetAddress"
                                autoComplete="street-address"
                                returnKeyType="next"
                                onSubmitEditing={() => zipRef.current?.focus()}
                                onSubmit={value => profileFieldUpdate({ address2: value })}
                            />
                            <FormNumericTextInput
                                ref={zipRef}
                                caption={formatMessage('profile.zip')}
                                initialValue={zip ?? null}
                                testID="Zipcode"
                                maxLength={5}
                                textContentType="postalCode"
                                autoComplete="postal-code"
                                returnKeyType="next"
                                onSubmitEditing={() => cityRef.current?.focus()}
                                onSubmit={value => profileFieldUpdate({ zip: value })}
                            />
                            <FormTextInput
                                ref={cityRef}
                                caption={formatMessage('profile.city')}
                                initialValue={city ?? null}
                                testID="City"
                                textContentType="addressCity"
                                returnKeyType="done"
                                onSubmit={value => profileFieldUpdate({ city: value })}
                            />
                        </FormGroup>

                        {employment && (
                            <>
                                <HelperButton title={formatMessage('profile.contact-info.helper')} />

                                <FormGroup caption={formatMessage('profile.work-header')}>
                                    <FormTextInput
                                        disabled
                                        caption={formatMessage('profile.work-employer')}
                                        initialValue={employment.company.name ?? null}
                                    />
                                    <FormEmailInput
                                        disabled
                                        caption={formatMessage('profile.work-email')}
                                        initialValue={workEmail ?? null}
                                    />
                                    <FormPhoneNumberInput
                                        disabled
                                        caption={formatMessage('profile.work-phone')}
                                        initialValue={workPhone ?? null}
                                    />
                                    <HelperButton title={formatMessage('profile.work-info')} />
                                </FormGroup>
                            </>
                        )}

                        <FormGroup caption={formatMessage('profile.language')}>
                            <FormRadioGroupInput<LanguageCode>
                                initialValue={lang}
                                options={[
                                    { option: 'FI', name: formatMessage('profile.language.fi') },
                                    { option: 'EN', name: formatMessage('profile.language.en') }
                                ]}
                                onSubmit={value => profileFieldUpdate({ lang: value })}
                            />
                        </FormGroup>
                        <FormGroup caption={formatMessage('profile.permissions')}>
                            <CheckGroup>
                                <CheckBox
                                    selected={marketingPermission}
                                    onPress={() => profileFieldUpdate({ marketingPermission: !marketingPermission })}
                                    label={formatMessage('onboarding-terms.accept-marketing.label')}
                                    helper={formatMessage('onboarding-terms.accept-marketing.helper')}
                                />
                                <CheckBox
                                    selected={memberCommunicationPermission}
                                    onPress={() =>
                                        profileFieldUpdate({
                                            memberCommunicationPermission: !memberCommunicationPermission
                                        })
                                    }
                                    label={formatMessage('onboarding-terms.accept-communications.label')}
                                    helper={formatMessage('onboarding-terms.accept-communications.helper')}
                                />
                                <CheckBox
                                    selected={feedbackPermission}
                                    onPress={() => profileFieldUpdate({ feedbackPermission: !feedbackPermission })}
                                    label={formatMessage('onboarding-terms.accept-feedback.label')}
                                    helper={formatMessage('onboarding-terms.accept-feedback.helper')}
                                />
                            </CheckGroup>
                        </FormGroup>
                        <Spacing.Vertical.LARGE />
                        {showMobileHeader && (
                            <>
                                <Box variant="negativeMarginContainer">
                                    <Box variant="contentDivider" />
                                </Box>
                                <Spacing.Vertical.LARGE />
                                <Button
                                    fillContainer
                                    type="negative"
                                    size="regular"
                                    label={formatMessage('profile.sign-out')}
                                    onPress={handleSignOut}
                                />
                            </>
                        )}
                        <Spacing.Vertical.LARGE />
                        <Button
                            fillContainer
                            type="negative"
                            size="regular"
                            label={formatMessage('profile.account-removal.title')}
                            onPress={handleAccountRemoval}
                        />
                    </Flex>
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
}

export function ProfileEditUpdateName() {
    const { formatMessage } = useIntl();
    const { navigate } = useNavigation<ProfileEditNavigationProp>();

    const handlePress = useCallback(() => navigate('profile-verify'), [navigate]);

    return (
        <Flex
            backgroundColor={Color.BACKGROUND_PRIMARY}
            borderRadius="16px"
            padding="16px 16px"
            marginTop="16px"
            marginBottom="16px"
            shadow={Shadow.primary}
        >
            <Text.INPUT_FIELD_TITLE after={8}>
                <FormattedMessage id="profile.verify" />
            </Text.INPUT_FIELD_TITLE>
            <Text.CAPTION>
                <FormattedMessage id="profile.verify.description" />
            </Text.CAPTION>

            <Button
                shape="rounded"
                size="small"
                label={formatMessage('profile.verify.authenticate')}
                onPress={handlePress}
                style={{ marginTop: Spacing.MEDIUM, alignSelf: 'flex-start' }}
            />
            <View style={{ position: 'absolute', right: 0, bottom: 0 }}>
                <BubbleLines fill={Color.BACKGROUND_DEFAULT} />
            </View>
        </Flex>
    );
}
