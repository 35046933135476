import { Flex, useBreakpointValue } from 'native-base';
import React from 'react';

import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { EmploymentFragment, UserProfileFragment } from '~/types';
import { isValidString } from '~/utils/type-predicates';

import { Color } from '../color';

export type UserProfileProps = UserProfileFragment & { employment: EmploymentFragment | null } & {
    isOnboarding?: boolean;
};

export const ProfileCard: React.FC<UserProfileProps> = props => {
    const {
        callName,
        firstName,
        lastName,
        ssn,
        email,
        privatePhone,
        address1,
        address2,
        zip,
        city,
        workEmail,
        workPhone,
        isOnboarding
    } = props;

    const { formatMessage } = useIntl();

    const showDesktopComponent: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    const alignName = isOnboarding ? { base: 'flex-start', md: 'center' } : { base: 'flex-start' };
    const basePaddingLeft = isOnboarding ? '0px' : '16px';
    // TODO: Add employer info
    //  const employer = employment?.company?.name;

    // for normal profile marginTop={{ base: '24px', md: '150px' }}, check enrolling and see how to handle

    // height: '64px',
    //     paddingBottom: '16px',
    //     backgroundColor: Color.BACKGROUND_PRIMARY,
    //     alignItems: 'flex-end',
    //     display: {
    //         base: 'flex',
    //         md: 'none'
    //     }

    return (
        <Flex
            flexGrow={1}
            paddingLeft={{ base: '16px', md: basePaddingLeft }}
            justifyContent="space-between"
            variant="standardFlexResponsive"
            width="100%"
        >
            <Flex justifyContent="space-around" width="100%">
                <Flex width="100%">
                    <Flex alignSelf={alignName}>
                        {showDesktopComponent ? (
                            <Text.H2 style={{ color: Color.HEADER, paddingBottom: 16 }}>
                                {`${callName ?? firstName} ${lastName}`}
                            </Text.H2>
                        ) : (
                            <Text.H1 style={{ color: Color.HEADER, paddingBottom: 24 }}>
                                {`${callName ?? firstName} ${lastName}`}
                            </Text.H1>
                        )}
                    </Flex>

                    {ssn ? (
                        <Flex alignSelf={alignName}>
                            <Text.LEAD_1>{ssn}</Text.LEAD_1>
                        </Flex>
                    ) : null}
                </Flex>
                <Spacing.Vertical.LARGE />
                {isOnboarding && (
                    <Flex variant="flexDesktopOnly" width="100%">
                        <Flex
                            height="1px"
                            width="100%"
                            borderBottomWidth="1px"
                            borderBottomColor={Color.LINE_DEFAULT}
                        />
                        <Spacing.Vertical.LARGE />
                    </Flex>
                )}
                <Text.SUBTITLE_1>
                    <FormattedMessage id="profile.contact-details" />
                </Text.SUBTITLE_1>
                <Spacing.Vertical.LARGE />
                {isValidString(privatePhone) && <Text.P1>{privatePhone}</Text.P1>}
                {isValidString(workPhone) && (
                    <Text.P1>
                        {workPhone} {formatMessage('profile.work-postfix')}
                    </Text.P1>
                )}
                <Spacing.Vertical.LARGE />
                {isValidString(email) && <Text.P1>{email}</Text.P1>}
                {isValidString(workEmail) && (
                    <Text.P1>{`${workEmail} ${formatMessage('profile.work-postfix')}`}</Text.P1>
                )}
                <Spacing.Vertical.LARGE />
                {isValidString(address1) && <Text.P1>{address1}</Text.P1>}
                {isValidString(address2) && <Text.P1>{address2}</Text.P1>}
                {isValidString(zip) && isValidString(city) && (
                    <Text.P1>
                        {zip} {city}
                    </Text.P1>
                )}
            </Flex>
        </Flex>
    );
};

ProfileCard.displayName = 'ProfileCard';
