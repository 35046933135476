import { useHeaderHeight } from '@react-navigation/elements';
import { CompositeNavigationProp, useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import { Flex } from 'native-base';
import React, { useCallback, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { LayoutChangeEvent } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ArticleCategoryView } from '~/components/article';
import { ButtonSheet } from '~/components/button';
import { CallToAction } from '~/components/call-to-action/call-to-action';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Divider } from '~/components/divider';
import { HeaderBackButton } from '~/components/header';
import { ChevronRightIcon } from '~/components/icon';
import { Loader } from '~/components/loader';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { AppError } from '~/error';
import { useArticle } from '~/hooks/article';
import { useContentWidths } from '~/hooks/content-widths/content-widths';
import { useMemberActions, Action } from '~/hooks/member-actions';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

export type NewContactNavigationProp = CompositeNavigationProp<
    StackNavigationProp<MainNavigatorParamList, 'new-contact'>,
    StackNavigationProp<AppNavigatorParamList>
>;

const ctaGap = 16;
const ctaMinWidth = 300;

export function NewContact() {
    const { navigate, goBack } = useNavigation<NewContactNavigationProp>();
    const { bottom } = useSafeAreaInsets();
    const linkTo = useLinkTo();
    const { formatMessage } = useIntl();
    const headerHeight = useHeaderHeight();
    const handleError = useErrorHandler();
    const [ctaWidth, setCtaWidth] = useState(300);

    const { maxWidth } = useContentWidths('default');

    const {
        loading: actionsLoading,
        error: actionsError,
        contactCategoryActions,
        nonBusyCategoryActions
    } = useMemberActions();

    const { loading: articleLoading, error: articleError, article } = useArticle({ tag: 'root_topical' });
    const loading = actionsLoading || articleLoading;

    const { onScroll } = useExtendedNavigationHeader({
        range: 20,
        title: formatMessage('new-contact.title'),
        headerLeft: <HeaderBackButton />
    });

    const handleItemPressed = useCallback((id: ID) => navigate('article', { id }), [navigate]);

    const onAction = (action: Action) => {
        const { actionUrl, title, type, description } = action;
        if (!actionUrl) {
            // chat action that requires contact reason
            navigate('contact-reason', { title, actionType: type, description });
        } else if (actionUrl.includes('tel')) {
            Linking.openURL(actionUrl).then();
        } else if (action.type === 'REPORT_SICK_LEAVE') {
            navigate('sick-leave', {});
        } else if (action.type === 'FAQ') {
            navigate('faq', { tag: 'root_faq' });
        } else {
            linkTo(actionUrl);
        }
    };

    if (actionsError) {
        handleError(
            new AppError(actionsError, 'error.cannot-load-member-actions', {
                onClose: goBack,
                name: 'error-overlay.go_back'
            })
        );
    }

    const onLayout = (event: LayoutChangeEvent) => {
        const containerWidth = event.nativeEvent.layout.width;
        if (containerWidth >= 3 * ctaMinWidth + 2 * ctaGap) {
            // 3 in a row
            setCtaWidth((containerWidth - 2 * ctaGap) / 3);
        } else if (containerWidth >= 2 * ctaMinWidth + ctaGap) {
            // 2 in a row
            setCtaWidth((containerWidth - ctaGap) / 2);
        } else {
            setCtaWidth(containerWidth);
        }
    };

    return (
        <ContentScrollView
            onScroll={onScroll}
            bounces
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ minHeight: '100%' }}
            overScrollMode="never"
        >
            <ContentHorizontalMargins includePadding>
                <Flex maxW={maxWidth} flexDir="column" marginBottom={bottom + 'px'} paddingTop={{ base: 2, md: 8 }}>
                    <Flex paddingTop={`${headerHeight}px`} paddingBottom={8}>
                        <Text.TITLE>
                            <FormattedMessage id="new-contact.title" />
                        </Text.TITLE>
                    </Flex>
                    {loading ? (
                        <Flex alignItems="center" justifyContent="center" paddingTop={32}>
                            <Loader size="medium" delay={500} layoutStyle={{ marginBottom: Spacing.GINORMOUS }} />
                        </Flex>
                    ) : (
                        <Flex>
                            <Flex direction="row" wrap="wrap" style={{ gap: 16 }} onLayout={onLayout}>
                                {contactCategoryActions.map(action => (
                                    <Flex key={action.title} w={ctaWidth}>
                                        <CallToAction
                                            key={action.title}
                                            type={action.type}
                                            title={action.title}
                                            descriptionShort={action.descriptionShort}
                                            description={action.description}
                                            onPress={() => onAction(action)}
                                        />
                                        <Spacing.Vertical.MEDIUM />
                                    </Flex>
                                ))}
                            </Flex>
                            {!articleError && article?.childCategories.length ? (
                                <>
                                    <Divider before={Spacing.SMALL} />
                                    <Flex>
                                        {article?.childCategories.map(category => (
                                            <ArticleCategoryView
                                                key={category.id}
                                                category={category}
                                                onItemPressed={handleItemPressed}
                                            />
                                        ))}
                                    </Flex>
                                </>
                            ) : null}
                            <Divider after={Spacing.LARGE} />
                            <Text.HEADER_3 numberOfLines={2} style={{ paddingHorizontal: Spacing.MEDIUM }}>
                                <FormattedMessage id="new-contact.non_busy_title" />
                            </Text.HEADER_3>
                            <ButtonSheet
                                carets
                                style={{ width: '100%' }}
                                items={nonBusyCategoryActions.map(action => {
                                    return {
                                        key: action.title,
                                        title: action.title,
                                        label: action.descriptionShort,
                                        right: ChevronRightIcon,
                                        onPress: () => {
                                            onAction(action);
                                        }
                                    };
                                })}
                            />
                        </Flex>
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
}
