import { CompositeNavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useLayoutEffect } from 'react';

import { HeaderTextButton } from '~/components/header/header-text-button';
import { HelttiHeaderStackHeaderWrapper } from '~/components/heltti-header';
import { ScreenContainer } from '~/components/screen';
import { AppointmentsSelectionProvider } from '~/contexts/appointments-selection';
import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

import { ViewAppointmentsContent } from './view-appointments-content';

export type AppointmentsNavigationProp = CompositeNavigationProp<
    StackNavigationProp<MainNavigatorParamList, 'appointments'>,
    StackNavigationProp<AppNavigatorParamList>
>;

export type AppointmentsRouteProp = RouteProp<MainNavigatorParamList, 'appointments'>;

export const Appointments: React.FC = () => {
    const { params } = useRoute<AppointmentsRouteProp>();
    const { goBack, setOptions } = useNavigation<AppointmentsNavigationProp>();
    const { formatMessage } = useIntl();

    const handleAbort = useCallback(() => {
        goBack();
    }, [goBack]);

    useLayoutEffect(() => {
        setOptions({
            header: (props: StackHeaderProps) => (
                <HelttiHeaderStackHeaderWrapper
                    {...props}
                    headerRight={<HeaderTextButton onPress={handleAbort} label={formatMessage('appointments.abort')} />}
                />
            )
        });
    }, [formatMessage, handleAbort, setOptions]);

    return (
        <ScreenContainer>
            <AppointmentsSelectionProvider
                calendarReservationAccessId={params.calendarReservationAccessId}
                calendarEventId={params.calendarEventId}
                initialAppointmentDaysRange={params.appointmentDaysRange}
                initialDate={params.date}
                initialTimeOfDay={params.timeOfDay}
                initialFilters={params.filters}
            >
                <ViewAppointmentsContent />
            </AppointmentsSelectionProvider>
        </ScreenContainer>
    );
};
