import { Box, Flex } from 'native-base';
import React from 'react';

import { ButtonSheet, ButtonSheetItemProps } from '~/components/button';
import { Color } from '~/components/color';
import { Sidebar, SidebarHeader, SidebarSection } from '~/components/sidebar';
import { Text } from '~/components/text';
import { UserInfo } from '~/components/user';
import { useIntl } from '~/contexts/intl';
import { ChatUserFragment } from '~/types';

export type ChatSidebarProps = {
    title: string;
    users?: ChatUserFragment[];
    messageInteractions: ButtonSheetItemProps[];
};

export const ChatSidebar: React.FC<ChatSidebarProps> = props => {
    const { messageInteractions, title, users } = props;
    const { formatMessage } = useIntl();

    return (
        <Flex>
            <Box mt="6" mb="4">
                {/* TODO: actual breadcrumbs */}
                <Text.KICKER style={{ color: Color.TEXT_SECONDARY }}>{'Koti > Viestit >'}</Text.KICKER>
            </Box>
            <Sidebar>
                <SidebarHeader title={title!} />
                <SidebarSection title={formatMessage('chat.participants')}>
                    {users?.map(user => (
                        <Box key={user.id} borderTopWidth="1" borderTopColor={Color.BORDER_DEFAULT}>
                            <UserInfo user={user.user} image avatarSize="small" />
                        </Box>
                    ))}
                </SidebarSection>
                {messageInteractions.length > 0 && (
                    <SidebarSection title={formatMessage('chat.active_tasks')}>
                        <ButtonSheet carets items={messageInteractions} />
                    </SidebarSection>
                )}
            </Sidebar>
        </Flex>
    );
};
