import { Flex, HStack, useBreakpointValue } from 'native-base';
import React, { useEffect } from 'react';

import { AppointmentsCalendar } from '~/components/appointment';
import { CheckBox } from '~/components/check';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { FormSelect } from '~/components/form/form-select';
import { LocationWithAppointmentSelect } from '~/components/form/location-with-appointment-select';
import { RegionSelect } from '~/components/form/region-select';
import { Loader } from '~/components/loader';
import { Sidebar, SidebarHeader, SidebarSection } from '~/components/sidebar';
import { useAnalyticsEventEffect } from '~/contexts/analytics';
import { AppointmentContactTypeValues, useAppointmentsSelection } from '~/contexts/appointments-selection';
import { useIntl } from '~/contexts/intl';
import { useAppointmentDays } from '~/hooks/appointment-days';
import { isNative } from '~/utils';
import { toISODateString } from '~/utils/date';

import { AppointmentsContentHeader } from './appointments-content-header';
import { AppointmentsContentList } from './appointments-content-list';
import { isFilterSet, toggleCheckFilter } from './appointments-filters-utils';

export const ViewAppointmentsContent = () => {
    const selection = useAppointmentsSelection();
    const { formatMessage } = useIntl();

    const {
        calendarReservationAccessId,
        region,
        setRegion,
        location,
        timeOfDay,
        filters,
        setFilters,
        date,
        setDate,
        appointmentDaysRange
    } = selection;
    const regionId = region?.id;
    const locationId = location?.id;

    const isDesktop: boolean = useBreakpointValue({
        base: false,
        md: true
    });

    useAnalyticsEventEffect(
        {
            type: 'appointments',
            action: 'view',
            detail1: `region=${region?.name}`,
            detail2: `location=${location?.name}`
        },
        [region, location]
    );

    const { loading, appointmentDays, fetchMoreAppointmentDays } = useAppointmentDays({
        start: appointmentDaysRange[0],
        end: appointmentDaysRange[1],
        calendarReservationAccessId,
        regionId,
        locationId,
        timeOfDay,
        filters
    });

    useEffect(() => {
        if (appointmentDays?.length) {
            if (!date) {
                const appointmentDay = appointmentDays?.find(day => toISODateString(day.date) === date);
                if (!appointmentDay) {
                    setDate(toISODateString(appointmentDays[0].date));
                }
            }
        }
    }, [appointmentDays, date, setDate]);

    useEffect(() => {
        if (appointmentDays?.length) {
            if (date) {
                const appointmentDay = appointmentDays?.find(day => toISODateString(day.date) === date);
                if (appointmentDay) {
                    return;
                }
            }
            setDate(toISODateString(appointmentDays[0].date));
        }
    }, [appointmentDays, date, setDate]);

    // TODO: track region changes
    // const { track } = useAnalytics();

    if (loading) {
        return <Loader size="medium" />;
    }

    const content = (
        <>
            <AppointmentsContentHeader selection={selection} loading={loading} appointmentDays={appointmentDays} />
            <AppointmentsContentList selection={selection} loading={loading} appointmentDays={appointmentDays} />
        </>
    );

    if (isNative()) {
        return content;
    }

    const contactTypeCheckboxes = AppointmentContactTypeValues.map(contactType => (
        <CheckBox
            key={contactType}
            selected={isFilterSet(filters, 'contactTypes', contactType)}
            label={formatMessage(`appointment.type.${contactType}`)}
            onPress={() => setFilters(toggleCheckFilter(filters, 'contactTypes', contactType))}
            style={{ marginTop: 5 }}
            disabled={!region && contactType === 'VISIT'}
        />
    ));

    return (
        <ContentHorizontalMargins contentWidth="full">
            <HStack width="100%" pt="6" flexWrap={isDesktop ? 'wrap' : 'nowrap'}>
                <Flex variant="standardFlexResponsive" alignSelf="stretch" flexGrow={1}>
                    <Sidebar>
                        <SidebarHeader title={formatMessage('profile.calendar-events.title')} />
                        <SidebarSection title={formatMessage('appointments.select-region')}>
                            <RegionSelect onSelect={setRegion} initialRegion={region} />
                        </SidebarSection>
                        <SidebarSection title={formatMessage('appointments.select-location')}>
                            {calendarReservationAccessId && regionId && date ? (
                                <LocationWithAppointmentSelect
                                    calendarReservationAccessId={calendarReservationAccessId}
                                    initialLocation={location}
                                    regionId={regionId}
                                    date={date}
                                    contactTypes={filters?.contactTypes?.slice()}
                                    timeOfDay={timeOfDay}
                                />
                            ) : (
                                <FormSelect disabled options={[]} />
                            )}
                        </SidebarSection>
                        <SidebarSection>
                            <Flex h={410}>
                                <AppointmentsCalendar
                                    date={date ?? toISODateString(new Date())}
                                    setSelectedDate={setDate}
                                    appointmentDays={appointmentDays}
                                    appointmentDaysRange={appointmentDaysRange}
                                    fetchMoreAppointmentDays={fetchMoreAppointmentDays}
                                />
                            </Flex>
                        </SidebarSection>
                        <SidebarSection title={formatMessage('appointment.type.label')}>
                            {contactTypeCheckboxes}
                        </SidebarSection>
                    </Sidebar>
                    <Flex grow={1} p="8">
                        {content}
                    </Flex>
                </Flex>
            </HStack>
        </ContentHorizontalMargins>
    );
};
